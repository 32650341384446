import React from 'react'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBRow, MDBCol, MDBCard } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class TipSection extends React.Component {
  render() {
    return (
      <>
        <MDBRow>
          <MDBCol lg="12">
            <div>
              <h3 className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large text-very-dark-blue' } >
                {this.props.title}
              </h3>
              {this.props.subtitle && (
                <h4 className={ this.props.subtitleClass != null ? this.props.subtitleClass : 'font-alt font-w-700 letter-spacing-1 mb-4 title-xs-large title-extra-large text-grayish-blue' } >
                  {this.props.subtitle}
                </h4>
              )}
              <div
                className="mt-3 font-w-400 text-medium"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: this.props.description }}
              />
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="py-4">
          {this.props.card.map((cards, index) => {
            return (
              <MDBCol key={index} md="6" lg="4" className="mt-5 mb-5">
                <MDBCard className="flex-fill">
                  <GatsbyImage
                    image={cards.image.childImageSharp.gatsbyImageData}
                    className="rounded-top"
                    alt={cards.alttext}
                  />
                </MDBCard>
              </MDBCol>
            )
          })}
        </MDBRow>

        {this.props.ctalink &&
        <MDBRow>
          <MDBCol md="9">
            <div className="content">
              <p className="mt-3 font-w-400 text-medium">
                {this.props.ctadescription}
              </p>
            </div>
          </MDBCol>
          <MDBCol md="3" className="text-center text-middle" style={{margin: "auto"}}>
            {this.props.ctalink.substring(0, 4) === "http" || this.props.ctalink.substring(0, 6) === "mailto" ?
              <a href={this.props.ctalink} className="btn btn-mdb-color btn-medium">
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.ctatext}
              </a>
            :
              <Link
                to={this.props.ctalink}
                className="btn btn-mdb-color btn-medium"
              >
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.ctatext}
              </Link> 
            }
          </MDBCol>
        </MDBRow>
        }
      </>
    )
  }
}

export default TipSection