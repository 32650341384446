import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class CiSection extends React.Component {
  render() {
    return (
      <>
        <MDBRow>
          <MDBCol lg="12">
            <div>
              <h3 className={this.props.titleClass ? this.props.titleClass : 'font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large text-very-dark-blue'}>
                {this.props.title}
              </h3>
              {this.props.subtitle && (
                <h4 className={this.props.subtitleClass ? this.props.subtitleClass : 'font-alt font-w-700 letter-spacing-1 mb-4 title-xs-large title-extra-large text-grayish-blue'}>
                  {this.props.subtitle}
                </h4>
              )}
              <div
                className="mt-3 mb-4 font-w-400 text-medium"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: this.props.description }}
              />
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="py-5">
          {this.props.card.map((cards, index) => {
            return (
              <MDBCol key={index} lg="4" md="6" className="pb-30 d-flex" > 
                <div className="card promoting-card flex-fill">
                  <div className="card-body d-flex flex-row card-hover" style={{ minHeight: '150px', maxHeight: '150px' }} >
                    <div style={{ minWidth: '85px', maxWidth: '85px', }} >
                      <GatsbyImage
                        image={cards.image.childImageSharp.gatsbyImageData}
                        className="mr-1"
                        alt={cards.alttext}
                      />
                    </div>
        
                    <div>
                      <div className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large pb-3">
                        {cards.title}
                      </div>
                      <a
                        href={cards.link}
                        target="_blank"
                        rel="noopener"
                        className="text-medium effect"
                      >
                        {cards.linktext}
                      </a>
                    </div>
                  </div>
                </div>
              </MDBCol>
            )
          })}
        </MDBRow>
        <MDBRow>
          <MDBCol lg="12" className="mb-3">
            <p className="font-w-400 text-medium">
              {this.props.ctadescription}
            </p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="12">
            <Link
              to={this.props.ctalink}
              className="btn btn-mdb-color btn-medium"
            >
              <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
              {this.props.ctatext}
            </Link>
          </MDBCol>
        </MDBRow>
      </>
    )
  }
}

export default CiSection