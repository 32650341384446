import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class AltSection extends React.Component {
  render() {
    return (
      <MDBRow
        className={`about-item align-items-center ${
          this.props.placement === 'right' ? 'flex-row-reverse' : ''
        }`} 
      >
        <MDBCol lg="6" className="order-2 order-lg-1">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="rounded"
          />
        </MDBCol>
        <MDBCol lg="6"className="mt-4 mt-lg-0 mb-4 mb-lg-0 order-1 order-lg-2">
          <div className="about-content">
            <h3
              className={
                this.props.titleClass != null
                  ? this.props.titleClass
                  : 'font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue'
              }
            >
              {this.props.title}
            </h3>
            {this.props.subtitle && (
              <h4
                className={
                  this.props.subtitleClass != null
                    ? this.props.subtitleClass
                    : 'font-alt font-w-700 letter-spacing-1 mb-4 title-xs-large title-extra-large text-grayish-blue'
                }
              >
                {this.props.subtitle}
              </h4>
            )}
            <div
              className="mt-3 font-w-400 text-medium"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />

            {this.props.link ? 
              this.props.link.charAt(0) == '/' ? (
                <Link
                  to={this.props.link}
                  className={`mt-4 ${this.props.colour}`}
                  aria-label={this.props.title}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              ) : (
                <a
                  href={this.props.link}
                  className={`mt-4 ${this.props.colour}`}
                  target="_blank"
                  rel="noopener"
                  aria-label={this.props.title}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              )
             : null}
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default AltSection